import { useRouter } from 'next/router'
import useSWR from 'swr'

import * as T from 'types'

import { fetch } from '../fetchers'
import getErrorMessage from './utils/getErrorMessage'

export const useListingPhotos = (listingId?: string | null) => {
  const router = useRouter()
  const key = listingId ? `/listings/${listingId || router.query.id}/photos` : null

  const response = useSWR<T.IPhoto[] | T.IServerError>(key, fetch)

  const error = getErrorMessage(response, 'photos', true)
  const photos = error ? undefined : (response.data as T.IPhoto[] | undefined)

  return { ...response, error, photos }
}
