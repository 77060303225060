import styled from 'styled-components'

import { ISVGProps } from './types'

export const SVGIcon = styled.div.attrs({ className: 'svg-icon' })<ISVGProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    width: ${props => props.width || 20}px;
    height: ${props => props.height || 20}px;
  }
  & path {
    fill: ${props => props.fill || props.theme.colors.black};
  }
`
