/* eslint-disable react/no-unused-prop-types */
import Link, { LinkProps } from 'next/link'
import { FunctionComponent } from 'react'
import styled from 'styled-components'

import Anchor from 'components/GlobalStyles/Anchor'

import { Either } from 'types'

import { ButtonColorType } from './types'

interface IStyledButtonProps {
  buttonColorType: ButtonColorType
}

const Button = styled.button<IStyledButtonProps>`
  cursor: pointer;
  font-size: ${props => props.theme.fonts.sizes[12]}px;
  font-weight: ${props => props.theme.fonts.weights.heavy};
  text-align: center;
  text-transform: uppercase;
  color: ${props => props.theme.colors.white};
  letter-spacing: 1.5px;
  border: none;
  border-radius: ${props => props.theme.radii[12]}px;
  padding: 14px 24px 12px 24px;
  height: 44px;
  width: inherit;
  z-index: inherit;

  ${props =>
    props.buttonColorType === 'colorTypeA'
    && `
      background-color: ${props.theme.colors.teal500};
  `}

  ${props =>
    props.buttonColorType === 'colorTypeB'
    && `
      background-color: ${props.theme.colors.gold600};
  `}

  &:hover,
  &:focus {
    ${props =>
    props.buttonColorType === 'colorTypeA'
      && `
        background-color: ${props.theme.colors.teal400};
    `}
    ${props =>
    props.buttonColorType === 'colorTypeB'
      && `
        background-color: ${props.theme.colors.gold500};
    `}
  }
`

type IButtonBaseProps = {
  colorType: ButtonColorType
  label?: string
  dataTestId?: string
  // link/action props
}

type ILink = {
  href?: LinkProps['href']
  as?: LinkProps['as']
  externalLink?: boolean
}

type IClickAction = {
  onClick?: () => void
}

type IButtonProps = IButtonBaseProps & Either<ILink, IClickAction>

export const StyledButton: FunctionComponent<IButtonProps> = props => (
  <Button
    buttonColorType={props.colorType}
    onClick={props.onClick}
  >
    {props.label || 'Click'}
  </Button>
)

const MarketingButton: FunctionComponent<IButtonProps> = ({
  href,
  as,
  externalLink,
  dataTestId,
  ...props
}) => {
  const { label } = props

  if (href) {
    return (
      <Link
        href={href || '/'}
        as={as}
        passHref
      >
        <Anchor
          data-testid={dataTestId || label}
          target={externalLink ? '_blank' : '_self'}
        >
          <StyledButton {...props} />
        </Anchor>
      </Link>
    )
  }

  return (
    <StyledButton
      {...props}
      data-testId={dataTestId}
    />
  )
}

export default MarketingButton
