import SVG from './SVG'
import * as T from './types'

const UpChevron = (props: T.ISVGProps) => (
  <SVG
    {...props}
    viewBox="0 0 12 7"
    d="M5.2779 0.22C5.41842 0.0793098 5.60905 0.00018 5.8079 0H6.1879C6.38631 0.0023 6.57618 0.0811198 6.7179 0.22L11.8479 5.36C11.9426 5.45388 11.9958 5.58168 11.9958 5.715C11.9958 5.84832 11.9426 5.97612 11.8479 6.07L11.1379 6.78C11.0457 6.87407 10.9196 6.92707 10.7879 6.92707C10.6562 6.92707 10.5301 6.87407 10.4379 6.78L5.9979 2.33L1.5579 6.78C1.46402 6.87466 1.33622 6.9279 1.2029 6.9279C1.06958 6.9279 0.941782 6.87466 0.847899 6.78L0.147899 6.07C0.0532428 5.97612 -1.5898e-09 5.84832 0 5.715C1.5898e-09 5.58168 0.0532428 5.45388 0.147899 5.36L5.2779 0.22Z"
  />
)

const DownChevron = (props: T.ISVGProps) => (
  <SVG
    {...props}
    viewBox="0 0 12 7"
    d="M6.7179 6.7079C6.57738 6.84859 6.38675 6.92772 6.1879 6.9279H5.8079C5.60949 6.9256 5.41962 6.84678 5.2779 6.7079L0.1479 1.5679C0.0531996 1.47402 0 1.34622 0 1.2129C-1e-08 1.07958 0.0531996 0.951782 0.1479 0.857899L0.8579 0.147899C0.9501 0.0538352 1.0762 0.000827842 1.2079 0.000827841C1.3396 0.000827839 1.4657 0.0538352 1.5579 0.147899L5.9979 4.5979L10.4379 0.147899C10.5318 0.0532428 10.6596 1.5899e-09 10.7929 0C10.9262 -1.5898e-09 11.054 0.0532428 11.1479 0.147899L11.8479 0.857899C11.9426 0.951782 11.9958 1.07958 11.9958 1.2129C11.9958 1.34622 11.9426 1.47402 11.8479 1.5679L6.7179 6.7079Z"
  />
)

const LeftChevron = (props: T.ISVGProps) => (
  <SVG
    {...props}
    viewBox="0 0 7 12"
    d="M0.219999 6.7179C0.0793093 6.57738 0.00018 6.38675 0 6.1879V5.8079C0.0023 5.60949 0.0811203 5.41962 0.22 5.2779L5.36 0.147899C5.45388 0.0531996 5.58168 -2e-08 5.715 0C5.84832 1e-08 5.97612 0.0531996 6.07 0.1479L6.78 0.8579C6.87407 0.9501 6.92707 1.0762 6.92707 1.2079C6.92707 1.3396 6.87407 1.4657 6.78 1.5579L2.33 5.9979L6.78 10.4379C6.87466 10.5318 6.9279 10.6596 6.9279 10.7929C6.9279 10.9262 6.87466 11.054 6.78 11.1479L6.07 11.8479C5.97612 11.9426 5.84832 11.9958 5.715 11.9958C5.58168 11.9958 5.45388 11.9426 5.36 11.8479L0.219999 6.7179Z"
  />
)

const RightChevron = (props: T.ISVGProps) => (
  <SVG
    {...props}
    viewBox="0 0 7 12"
    d="M6.7079 5.2779C6.84859 5.41842 6.92772 5.60905 6.9279 5.8079V6.1879C6.9256 6.38631 6.84678 6.57618 6.7079 6.7179L1.5679 11.8479C1.47402 11.9426 1.34622 11.9958 1.2129 11.9958C1.07958 11.9958 0.951782 11.9426 0.857899 11.8479L0.147899 11.1379C0.0538351 11.0457 0.000827726 10.9196 0.000827726 10.7879C0.000827726 10.6562 0.0538351 10.5301 0.147899 10.4379L4.5979 5.9979L0.147899 1.5579C0.0532428 1.46402 0 1.33622 0 1.2029C0 1.06958 0.0532428 0.941782 0.147899 0.847899L0.857899 0.147899C0.951782 0.0532428 1.07958 0 1.2129 0C1.34622 0 1.47402 0.0532428 1.5679 0.147899L6.7079 5.2779Z"
  />
)

export { UpChevron, DownChevron, LeftChevron, RightChevron }
