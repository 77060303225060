import { FunctionComponent, useContext, useEffect, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'

import { interpolate, reverseInterpolate } from 'modules/OldSliders/SalePriceSlider/utils'

import MinusSquared from 'components/SVG/MinusSquared'
import PlusSquared from 'components/SVG/PlusSquared'

import { addCommas } from 'utils/stringFormatting'

import SliderInput from './SliderInput'

const FullWidthContainer = styled.div`
  width: 100%;
`
interface IProps {
  popoverLabel?: string
  price?: number
  sliderMin?: number
  sliderMax?: number
  removePopover?: boolean
  removeControlIcons?: boolean
  onChange?: (price: number) => void
}

const SalePriceSlider: FunctionComponent<IProps> = ({
  popoverLabel,
  price = 320000,
  sliderMin = 0,
  sliderMax = 150,
  removePopover,
  removeControlIcons,
  onChange,
}) => {
  const theme = useContext(ThemeContext)

  // slider state
  const [value, setValue] = useState(0)

  const handleSliderChange = (_: string, newValue: number) => {
    if (onChange) onChange(interpolate(newValue))

    setValue(newValue)
  }

  useEffect(() => {
    let tickValue = reverseInterpolate(price)
    if (tickValue < sliderMin) tickValue = sliderMin

    if (tickValue > sliderMax) tickValue = sliderMax

    setValue(tickValue)
  }, [price])

  return (
    <FullWidthContainer>
      <SliderInput
        max={sliderMax}
        min={sliderMin}
        step={1}
        name="sale-price"
        value={value}
        marginTop={removePopover ? undefined : 120}
        onChange={handleSliderChange}
        skipDebounce
        popoverLabel={popoverLabel}
        popoverValue={`$${addCommas(interpolate(value).toFixed(0))}`}
        sliderLeftIcon={
          removeControlIcons
            ? undefined
            : (
              <MinusSquared
                width={32}
                height={32}
                fill={theme.colors.teal500}
              />
            )
        }
        sliderRightIcon={
          removeControlIcons
            ? undefined
            : (
              <PlusSquared
                width={32}
                height={32}
                fill={theme.colors.blue500}
              />
            )
        }
        removePopover={removePopover}
      />
    </FullWidthContainer>
  )
}

export default SalePriceSlider
