import { FC, RefObject, useContext } from 'react'
import styled, { ThemeContext, keyframes } from 'styled-components'
import { ColorName } from 'themes/colors'

import { DownChevron } from 'components/SVG/Chevron'

import { SizeType } from 'types/enums'

interface IBackgroundColorProp {
  backgroundColor: ColorName
}

const floatingAnimation = keyframes`
  0% {
    transform: translateY(-50%);
  }
  25% {
    transform: translateY(-55%);
  }
  50% {
    transform: translateY(-50%);
  }
`

const DownArrowCircleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const DownArrowCircle = styled.div<IBackgroundColorProp>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors[props.backgroundColor]};
  border-radius: 50%;
  transform: translateY(-50%);
  box-shadow: ${props => props.theme.shadows.neutral.level1};
  cursor: pointer;

  animation-duration: 2s;
  animation-name: ${floatingAnimation};
  animation-iteration-count: infinite;
  animation-direction: alternate;
`

const DownwardCircleSmall = styled(DownArrowCircle)`
  width: 36px;
  height: 36px;
`

const DownwardCircleMedium = styled(DownArrowCircle)`
  width: 48px;
  height: 48px;
`

const DownwardCircleLarge = styled(DownArrowCircle)`
  width: 56px;
  height: 56px;
`

const DownwardCircleExtraLarge = styled(DownArrowCircle)`
  width: 64px;
  height: 64px;
`

const DownArrowCircleResponsive = styled(DownArrowCircle)`
  width: 48px;
  height: 48px;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    width: 56px;
    height: 56px;
  }
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    width: 64px;
    height: 64px;
  }
`

type DownArrowCirceSize = SizeType.small | SizeType.medium | SizeType.large | SizeType.extraLarge
interface IProps {
  size?: DownArrowCirceSize
  scrollTopOffset?: number
  destinationRef?: RefObject<HTMLDivElement> | null
  fill: ColorName
}

interface IIconProps {
  backgroundColor: ColorName
  size?: DownArrowCirceSize
  onClick?: () => void
}

const DownArrowCircleIcon: FC<IIconProps> = ({ size, ...props }) => {
  switch (size) {
    case SizeType.small:
      return <DownwardCircleSmall {...props} />
    case SizeType.medium:
      return <DownwardCircleMedium {...props} />
    case SizeType.large:
      return <DownwardCircleLarge {...props} />
    case SizeType.extraLarge:
      return <DownwardCircleExtraLarge {...props} />
    default:
      return <DownArrowCircleResponsive {...props} />
  }
}

const DownArrowCircleButton: FC<IProps> = ({ fill, size, destinationRef, scrollTopOffset = 0 }) => {
  const theme = useContext(ThemeContext)

  const top = (destinationRef?.current?.offsetTop || 0) - scrollTopOffset
  const scrollTo = () => window.scrollTo({ top, behavior: 'smooth' })

  return (
    <DownArrowCircleContainer>
      <DownArrowCircleIcon
        size={size}
        onClick={scrollTo}
        backgroundColor={fill}
      >
        <DownChevron
          fill={theme.colors.white}
          width="40%"
        />
      </DownArrowCircleIcon>
    </DownArrowCircleContainer>
  )
}

export default DownArrowCircleButton
