import dayjs from 'dayjs'
import Link from 'next/link'
import { FunctionComponent } from 'react'
import styled from 'styled-components'

import PlaceMarker from 'design-system/Iconography/SVG/PlaceMarker'
import Body2 from 'design-system/Typography/Body/Body2'
import Body3 from 'design-system/Typography/Body/Body3'
import Body4 from 'design-system/Typography/Body/Body4'
import Title1 from 'design-system/Typography/Title/Title1'

import BackgroundImage from 'components/BackgroundImage'
import Anchor from 'components/GlobalStyles/Anchor'
import Spacer from 'components/Spacer'

import { useListingPhotos } from 'services/swr/useListingPhotos'

import { formatAddress } from 'utils/listingHelpers'
import { addCommas } from 'utils/stringFormatting'

import * as T from 'types'
import * as E from 'types/enums'

const ViewListingCardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 410px;
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    height: 455px;
  }
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.radii[24]}px;
  box-shadow: ${props => props.theme.shadows.neutral.level4};
`

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  border-radius: ${props => props.theme.radii[24]}px ${props => props.theme.radii[24]}px 0 0;
`

const SavingsLabel = styled(Body3)`
  position: absolute;
  padding: 6px 10px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.gold600};
  border-radius: ${props => props.theme.radii[12]}px;
  top: 20px;
  right: 24px;
  z-index: ${props => props.theme.zIndices.level1};
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
`

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
`

const PriceRow = styled(InfoRow)`
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`

const AddressRow = styled.div`
  display: flex;
  align-items: flex-start;
`

interface IProps {
  listing: T.IListing
  soldListing?: boolean
  externalLink?: boolean
}

const ListingCard: FunctionComponent<IProps> = ({ listing, soldListing, externalLink }) => {
  const { photos } = useListingPhotos(listing._id)

  let bath = listing.bath ? listing.bath : 0

  if (listing.bathHalf) {
    bath += listing.bathHalf ? 0.5 : 0
  }

  const firstPublishedDate = dayjs(listing.firstPublishedAt)
  const listingSoldDate = dayjs(
    listing.contractDetails?.contractDate || listing.contractDetails?.closingDate,
  )
  const daysOnMarket = listingSoldDate.diff(firstPublishedDate, 'day')

  const paidPackagePrice = listing.pricingPackage.price

  const commissionSavings = Math.round(
    listing.price * ((6 - listing.commission) / 100) - paidPackagePrice,
  )

  return (
    <Link
      href={{ pathname: '/listing/[id]', query: { isSold: soldListing } }}
      as={`/listing/${listing.customLinkUrl}`}
      key={listing._id}
      prefetch={false}
      passHref
    >
      <Anchor target={externalLink ? '_blank' : '_self'}>
        <ViewListingCardContainer>
          <SavingsLabel>{`Saved $${addCommas(commissionSavings)}`}</SavingsLabel>
          <ImageContainer>
            <BackgroundImage
              backgroundSize="cover"
              imgSrc={photos ? photos[0].url : ''}
            />
          </ImageContainer>
          <InfoContainer>
            <PriceRow>
              <Title1>{listing.price ? `$${addCommas(listing.price)}` : ''}</Title1>
              <Spacer size={10} />
              <Body2 color="neutral400">{`${daysOnMarket} days on the market`}</Body2>
            </PriceRow>
            <Spacer size={10} />
            <AddressRow>
              <PlaceMarker width={14} />
              <Spacer size={4} />
              <Body4>{formatAddress(listing, true)}</Body4>
            </AddressRow>
            <Spacer size={10} />
            <InfoRow>
              {listing.propertyType !== E.HomeTypeEnum.land
                ? (
                  <>
                    <Body4 tag="span">{`${listing.bed ? addCommas(listing.bed) : 0} Bed`}</Body4>
                    <Spacer size={10} />
                    <Body4 tag="span">{`${addCommas(bath)} Bath`}</Body4>
                    <Spacer size={10} />
                    <Body4 tag="span">{`${listing.sqft ? addCommas(listing.sqft) : 0} Sqft`}</Body4>
                  </>
                )
                : (
                  <Body4 tag="span">{`${listing.lotSize || 0} ${
                    listing.lotSizeType || 'Acres'
                  }`}</Body4>
                )}
            </InfoRow>
          </InfoContainer>
        </ViewListingCardContainer>
      </Anchor>
    </Link>
  )
}

export default ListingCard
