import { FunctionComponent, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

/*
  @NOTE:
  Image component can be used best for rendering images with defined height and width.
  It is extended version of an html img tag and it uses src-set to render given iamges on each breakpoints.
  border-radius css property can also be inherited from its parent element.
*/

interface IProps {
  imgSrc: string
  imgSrcMobile?: string
  imgSrcTablet?: string
  imgSrcDesktop?: string
  alt?: string
  borderRadius?: number
  boxShadow?: string
}

interface IImageSourceProps {
  borderRadius?: number
  boxShadow?: string
}
const ImageSource = styled.img<IImageSourceProps>`
  width: 100%;
  height: 100%;
  border-radius: ${props => `${props.borderRadius ? `${props.borderRadius}px` : 'inherit'}`};
  ${props => props.boxShadow && ` box-shadow: ${props.boxShadow};`}
`

const Image: FunctionComponent<IProps> = ({
  imgSrc,
  imgSrcMobile,
  imgSrcTablet,
  imgSrcDesktop,
  alt = '',
  borderRadius,
  boxShadow,
}) => {
  const theme = useContext(ThemeContext)

  const { phone, tablet, desktop } = theme.metrics

  const srcSet = `
    ${imgSrcMobile || imgSrc} ${phone}w,
    ${imgSrcTablet || imgSrc} ${tablet}w,
    ${imgSrcDesktop || imgSrc} ${desktop}w
  `

  return (
    <ImageSource
      src={imgSrc}
      srcSet={srcSet}
      sizes={`
        (max-width: ${phone}px) 100vw,
        (max-width: ${tablet}px) 100vw,
        (max-width: ${desktop}px) 100vw,
      `}
      alt={alt}
      borderRadius={borderRadius}
      boxShadow={boxShadow}
    />
  )
}

export default Image
