import { Fragment, FunctionComponent, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import * as T from 'modules/Marketing/types'

import Body2 from 'design-system/Typography/Body/Body2'

import Spacer from 'components/Spacer'

import { addCommas } from 'utils/stringFormatting'

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

interface IReverseProp {
  reverse?: boolean
}
const Card = styled.div<IReverseProp>`
  display: flex;
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
`

const PersonIconContainer = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.gold500};
  flex-shrink: 0;
  margin-top: 10px;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    margin-top: 0;
    width: 88px;
    height: 88px;
  }

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    width: 124px;
    height: 124px;
  }
`

const PersonIcon = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  padding: 3px;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    padding: 6px;
  }
`

const CustomerTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${props => props.theme.colors.gold50};
  border-radius: ${props => props.theme.radii[24]}px;
  padding: 25px;
`

const PopoverPointerShape = styled.div<IReverseProp>`
  width: 0;
  height: 0;
  margin-top: 20px;
  z-index: ${props => props.theme.zIndices.level2};
  ${props =>
    props.reverse
      ? `
    border-top: 10px solid transparent;
    border-left: 15px solid ${props.theme.colors.gold50};
    border-bottom: 10px solid transparent;
  `
      : `
    border-top: 10px solid transparent;
    border-right: 15px solid ${props.theme.colors.gold50};
    border-bottom: 10px solid transparent;
  `}

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    margin-top: 30px;
  }

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    margin-top: 50px;
    ${props =>
    props.reverse
      ? `
    border-top: 20px solid transparent;
    border-left: 30px solid ${props.theme.colors.gold50};
    border-bottom: 20px solid transparent;
  `
      : `
    border-top: 20px solid transparent;
    border-right: 30px solid ${props.theme.colors.gold50};
    border-bottom: 20px solid transparent;
  `}
  }
`

const BoldText = styled.span`
  font-weight: ${props => props.theme.fonts.weights.heavy};
`

interface IProps {
  data: T.Testimonial[]
  type: 'homepage' | 'how-it-works'
}
const Testimonials: FunctionComponent<IProps> = ({ data: testimonials, type }) => {
  const theme = useContext(ThemeContext)

  return (
    <CardsContainer>
      {testimonials.map((testimonial, index) => {
        const emphasis = testimonial.highlight.length > 1

        const reviews = emphasis
          ? testimonial.review.split(testimonial.highlight)
          : testimonial.review
        return (
          <Fragment key={testimonial.name}>
            {index > 0 && (
              <Spacer
                size={theme.spacings[36]}
                tabletSize={theme.spacings[40]}
                desktopSize={theme.spacings[56]}
              />
            )}
            <Card
              key={`${testimonial.name}-testimonial`}
              reverse={index === 1}
            >
              <PersonIconContainer>
                <PersonIcon
                  src={testimonial.imgUrl}
                  alt={testimonial.name}
                />
              </PersonIconContainer>
              <Spacer
                size={theme.spacings[12]}
                desktopSize={theme.spacings[16]}
              />
              <PopoverPointerShape reverse={index === 1} />
              <CustomerTextContainer>
                {emphasis
                  ? (
                    <Body2>
                      {reviews[0]}
                      <BoldText>{testimonial.highlight}</BoldText>
                      {reviews[1]}
                    </Body2>
                  )
                  : (
                    <Body2>{reviews}</Body2>
                  )}
                <Spacer size={theme.spacings[16]} />
                {type === 'homepage' && (
                  <Body2 color="neutral400">
                    {`- ${testimonial.name}, sold their home ${
                      testimonial.location ? `in ${testimonial.location}` : ''
                    } and saved $${addCommas(testimonial.savings)} of their equity.`}
                  </Body2>
                )}
                {type === 'how-it-works' && (
                  <Body2 color="neutral400">
                    {`- ${testimonial.name}, ${testimonial.location}`}
                  </Body2>
                )}
              </CustomerTextContainer>
            </Card>
          </Fragment>
        )
      })}
    </CardsContainer>
  )
}

export default Testimonials
