/* eslint-disable camelcase */
import { useRouter } from 'next/router'
import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import styled, { ThemeContext } from 'styled-components'
import { v4 as uuid } from 'uuid'

import MarketingButton from 'design-system/Buttons/MarketingButton'
import { ButtonColorType } from 'design-system/Buttons/types'
import PlaceMarker from 'design-system/Iconography/SVG/PlaceMarker'

import ErrorBox from 'components/ErrorBox'
import Spacer from 'components/Spacer'

import { track, trackNewSignUpAddress } from 'services/analytics'
import { QueryParamsContext } from 'services/contexts'
import { useUser } from 'services/swr/useUser'
import { validateZip } from 'services/zips'

import formatLocation from 'utils/formatLocation'
import validateStreet from 'utils/inputValidation/streetAddress'
import { isChrome } from 'utils/userAgent'

import * as T from 'types'

const NO_GOOGLE_MATCH
  = 'Please retype the address and select one of the Google Maps suggestions. If none of the options match your address or there are no suggestions, please'

const GOOGLE_MAPS_ERROR = 'Google Maps does not recognize this address. Please try again or'
const LOOKUP_ERROR = "We couldn't look up on your address right now. Please try again or"
const NO_ZIP_ERROR = 'No zipcode found. Please reselect a valid address or'

const NO_STREET_ERROR
  = 'Street address does not include a street number. Please reselect a valid address or'

const placesAutocompleteSearchOptions = {
  componentRestrictions: { country: 'us' },
  types: ['address'],
}

interface IDropShadowProp {
  dropShadow?: string
}

interface IStackLayoutProp {
  stackLayout?: boolean
}

const AddressSearchInputContainer = styled.div<IStackLayoutProp>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: ${props => props.theme.zIndices.level5};

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    flex-direction: row;
    max-width: 700px;
    ${props =>
    props.stackLayout
      && `
      flex-direction: column;
      width: 100%;
    `}
  }
`
interface IErrorMessageProps {
  errorTopPosition?: string
}
const ErrorsContainer = styled.div<IErrorMessageProps>`
  position: absolute;
  width: 100%;
  top: 52px;
  left: 0;
`

const PlaceAutoCompleteContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`

interface IErrorProps {
  error: boolean
}
interface IInvalidProps {
  invalid?: boolean
}
const Input = styled.input.attrs({ type: 'text' })<IErrorProps & IInvalidProps>`
  width: 100%;
  height: 44px;
  padding-top: 2px; /* font correction */
  padding-left: 50px; /* left padding including the place marker icon */
  font-size: ${props => props.theme.fonts.sizes[16]}px;
  line-height: 24px;
  color: ${props => props.theme.colors.neutral900};
  caret-color: ${props => props.theme.colors.teal500};
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.neutral200};
  box-shadow: ${props => props.theme.shadows.neutral.level4};
  border-radius: ${props => props.theme.radii[4]}px;
  ${props =>
    props.error
    && `
  background: ${props.theme.colors.red50};
  border: 1px solid ${props.theme.colors.red500};
`}

  &::placeholder, ::-webkit-input-placeholder {
    color: ${props => props.theme.colors.neutral300};
  }

  & :active,
  :focus {
    outline: none;
    border: 1px solid ${props => props.theme.colors.teal500};
  }
`

const PlaceInputContainer = styled.div<IDropShadowProp>`
  display: flex;
  position: relative;
  width: 100%;
  z-index: ${props => props.theme.zIndices.level2};
`

const InputIconContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 24px;
`

interface IActiveProp {
  active?: boolean
}
const DropdownContainer = styled.div<IActiveProp>`
  display: none;
  position: absolute;
  top: 44px;
  left: 0;
  background-color: ${props => props.theme.colors.white};
  border: none;
  width: 100%;
  margin-top: 8px;
  border-radius: ${props => props.theme.radii[4]}px;
  z-index: ${props => props.theme.zIndices.level5};

  ${props => props.active && `box-shadow: ${props.theme.shadows.neutral.level3};`}

  ${Input}:focus + & {
    display: block;
  }
`

const AutocompleteOption = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${props => props.theme.fonts.weights.medium};
  font-size: ${props => props.theme.fonts.sizes[14]}px;
  color: ${props => props.theme.colors.neutral900};
  margin: 4px;
  min-height: 32px;
  padding: 0 8px;
  text-align: left;

  &:hover {
    color: ${props => props.theme.colors.blue500};
  }

  &:active {
    color: ${props => props.theme.colors.blue500};
    background-color: ${props => props.theme.colors.teal50};
    border-radius: ${props => props.theme.radii[4]}px;
  }

  cursor: pointer;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    font-size: ${props => props.theme.fonts.sizes[16]}px;
  }
`

const SavingsButtonContainer = styled.div<IStackLayoutProp>`
  width: 100%;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    width: 188px;
    ${props => props.stackLayout && `width: 100%;`}
  }
`

const initialAddressData: T.ICreateListingData = {
  aptNum: '',
  city: '',
  country: '',
  county: '',
  latitude: 0,
  longitude: 0,
  state: '',
  street: '',
  zip: '',
}

interface IProps {
  type: ButtonColorType
  addressInputRef?: React.RefObject<HTMLInputElement>
}

const AddressSearchInput: FunctionComponent<IProps> = ({ type, addressInputRef }) => {
  const theme = useContext(ThemeContext)

  const [isStackLayout, setIsStackLayout] = useState(false)
  const inputRef = useCallback(node => {
    if (node !== null && node.clientWidth < 480) {
      setIsStackLayout(true)
    } else {
      setIsStackLayout(false)
    }
  }, [])

  const router = useRouter()

  // query for appending UTM params
  const { params: query } = useContext(QueryParamsContext)

  // user (to check if logged in)
  const { user, isValidating } = useUser()
  const [initialPageLoad, setInitialPageLoad] = useState(true)
  const [loadingUser, setLoadingUser] = useState(true)

  useEffect(() => {
    if (initialPageLoad) return setInitialPageLoad(false)

    if (isValidating) return

    setLoadingUser(false)
  }, [user, isValidating])

  // autocomplete
  const [autoComplete, setAutoComplete] = useState('off')
  useEffect(() => {
    const mode = isChrome() ? 'nope' : 'off'
    setAutoComplete(mode)
  }, [])

  // error state
  const [error, setError] = useState('')
  const [zipError, setZipError] = useState('')

  const resetErrors = () => {
    if (error) setError('')

    if (zipError) setZipError('')
  }

  const [submitted, setSubmitted] = useState(false)

  const [address, setAddress] = useState('')
  const [addressParts, setAddressParts] = useState<T.ICreateListingData>(initialAddressData)
  const [addressSelected, setAddressSelected] = useState(false)

  const [covered, setCovered] = useState(false)

  const getCovered = useCallback(
    async (zip: string) => {
      const validZip = await validateZip(zip)
      setCovered(validZip)
      setZipError(validZip ? '' : 'Your area is not yet covered,')
      if (!validZip) {
        track('Uncovered Address', {
          city: addressParts?.city,
          county: addressParts?.county,
          state: addressParts?.state,
          zip: addressParts?.zip,
          street: addressParts?.street,
          ...query,
        })
        track('error.region_not_covered', {
          city: addressParts?.city,
          county: addressParts?.county,
          state: addressParts?.state,
          zip: addressParts?.zip,
          street: addressParts?.street,
          email: user?.email,
          name: user?.name,
          ...query,
        })
        setAddressParts(initialAddressData)
        setAddress('')
      }
    },
    [addressSelected],
  )

  useEffect(() => {
    if (!addressParts.zip) return

    getCovered(addressParts.zip)
  }, [addressParts.zip])

  useEffect(() => {
    if (error) {
      setAddress('')
      setAddressSelected(false)
    }
  }, [error])

  const updateAddressParts = async (geocoderResult: google.maps.GeocoderResult) => {
    if (!geocoderResult) return setError(GOOGLE_MAPS_ERROR)

    const locationData = await formatLocation(geocoderResult)
    if (!locationData.zip) return setError(NO_ZIP_ERROR)

    if (!validateStreet(locationData.street || '')) return setError(NO_STREET_ERROR)

    setAddressParts({ ...addressParts, ...locationData })
  }

  const handleAddressSelect = async (selectedAddress: string) => {
    try {
      const results = await geocodeByAddress(selectedAddress)

      if (results && !!results[0]) {
        updateAddressParts(results[0])
        setAddress(results[0]?.formatted_address)
        setAddressSelected(true)
      }
    } catch (err) {
      setError(LOOKUP_ERROR)
    }
  }

  const handleAddressChange = (value: string) => {
    setAddress(value)
    setAddressSelected(false)
    resetErrors()
  }

  const handleSubmit = () => {
    if (!addressSelected) return setError(NO_GOOGLE_MATCH)

    setSubmitted(true)

    const adressQuery = Object.keys(addressParts)
      .map(key => `${key}=${addressParts[key]}`)
      .join('&')

    const refererQuery = Object.keys(query)
      .map(key => `${key}=${query[key]}`)
      .join('&')
    trackNewSignUpAddress(addressParts, user, query)
    if (!covered) return setZipError('Your area is not yet covered,')

    setZipError('')
    router.push(`/get-started?${adressQuery}${refererQuery ? `&${refererQuery}` : ''}`)
  }

  if (loadingUser) return null

  if (user) {
    return (
      <MarketingButton
        colorType={type}
        href="/listing/create"
        label="See Your Savings"
      />
    )
  }

  return (
    <AddressSearchInputContainer stackLayout={isStackLayout}>
      <PlaceAutoCompleteContainer>
        <PlacesAutocomplete
          value={address}
          onChange={handleAddressChange}
          onSelect={handleAddressSelect}
          searchOptions={placesAutocompleteSearchOptions}
          shouldFetchSuggestions={address.length > 2}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <PlaceInputContainer ref={inputRef}>
              <InputIconContainer>
                <PlaceMarker
                  width={16}
                  fill={theme.colors.neutral300}
                />
              </InputIconContainer>
              <Input
                {...getInputProps({
                  className: 'street-address',
                })}
                data-testid="enterYourAddress"
                ref={addressInputRef}
                error={(!!error && !addressSelected) || (!!zipError && !covered)}
                name={uuid()} // force browser not to suggest previous inputs
                placeholder="Enter Your Address"
                autoComplete={autoComplete}
                disabled={submitted}
              />
              <DropdownContainer active={suggestions.length > 0}>
                {loading && null}
                {suggestions.map(suggestion => (
                  <AutocompleteOption
                    {...getSuggestionItemProps(suggestion)}
                    // not including key causes a warning, but adding it to the component
                    // causes the options not to display + selection to stop working locally
                    // key={suggestion.placeId}
                  >
                    {suggestion.description.replace(', USA', '')}
                  </AutocompleteOption>
                ))}
              </DropdownContainer>
              <ErrorsContainer>
                {!!zipError && (
                  <ErrorBox
                    error={zipError}
                    showLinkMessage
                    linkText="please see here for a list of states that we currently cover."
                    linkUri="/licensing"
                  />
                )}
                {!!error && (
                  <ErrorBox
                    error={error}
                    showChatMessage={!addressParts.listing || error === NO_GOOGLE_MATCH}
                    chatMessage="chat with us for help."
                    prepopulatedIntercomMessage="None of the Google Maps suggestions match my address/there are no suggestions."
                  />
                )}
              </ErrorsContainer>
            </PlaceInputContainer>
          )}
        </PlacesAutocomplete>
      </PlaceAutoCompleteContainer>
      {isStackLayout
        ? <Spacer size={12} />
        : (
          <Spacer
            size={24}
            responsiveSize={12}
          />
        )}
      <SavingsButtonContainer stackLayout={isStackLayout}>
        <MarketingButton
          colorType={type}
          label="See Your Savings"
          onClick={handleSubmit}
        />
      </SavingsButtonContainer>
    </AddressSearchInputContainer>
  )
}

export default AddressSearchInput
