import { SVGIcon } from '../Icons'
import { ISVGProps } from '../types'

const PlaceMarker = (props: ISVGProps) => (
  <SVGIcon {...props}>
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00033 6.99992C5.26699 6.99992 4.66699 6.39992 4.66699 5.66659C4.66699 4.93325 5.26699 4.33325 6.00033 4.33325C6.73366 4.33325 7.33366 4.93325 7.33366 5.66659C7.33366 6.39992 6.73366 6.99992 6.00033 6.99992ZM10.0003 5.79992C10.0003 3.37992 8.23366 1.66659 6.00033 1.66659C3.76699 1.66659 2.00033 3.37992 2.00033 5.79992C2.00033 7.35992 3.30033 9.42659 6.00033 11.8933C8.70033 9.42659 10.0003 7.35992 10.0003 5.79992ZM6.00033 0.333252C8.80032 0.333252 11.3337 2.47992 11.3337 5.79992C11.3337 8.01325 9.55366 10.6333 6.00033 13.6666C2.44699 10.6333 0.666992 8.01325 0.666992 5.79992C0.666992 2.47992 3.20033 0.333252 6.00033 0.333252Z"
        fill="black"
      />
    </svg>
  </SVGIcon>
)

export default PlaceMarker
